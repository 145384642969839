/* eslint-disable no-useless-escape */

const clearFormatMoney = (stringPrice) => {
  if (typeof stringPrice === 'number') {
    return stringPrice
  }
  if (typeof stringPrice === 'string') {
    const clearOne = stringPrice.replace(/\./g, '')
    const clearTwo = clearOne.replace(/\,/g, '.')

    return Number(clearTwo)
  }
  return 0
}
export default clearFormatMoney
